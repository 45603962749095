<template>
  <div class="container">
    <PublicHeader :totalName="$t('safeCenter').title" />
    <div class="item" @click="$router.push({ name: 'EditLoginPwd' })">{{ $t('safeCenter').editLoginPwd }}<van-icon
        name="arrow" color="#999999" size="18" /> </div>
    <div class="item" @click="setPayPassword">{{ $t('safeCenter').setPayPwd }}<van-icon name="arrow" color="#999999"
        size="18" />
    </div>
    <div class="item" @click="$router.push({ name: 'EditPayPwd' })">{{ $t('safeCenter').editPayPwd }}<van-icon
        name="arrow" color="#999999" size="18" /> </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  async mounted() {
    await userApi.getAccountBase()
  },
  methods: {
    // 设置交易密码
    async setPayPassword() {
      const res = await userApi.getAccountBase()
      if (res.Data.BindFundPassword)
        this.$toast({ message: this.$t('safeCenter').setPayedMsg })
      else
        this.$router.push({ name: 'SetPayPwd' })
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 70px 13px 0 13px;
  background: #fff;

  .item {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #333333;
    border-bottom: 1px solid #e9e9e9;
  }

}
</style>
